import React from 'react';

const MainContainer = ({children}) => {
    return(
        <div>
            {children}
        </div>
    )
}

export default MainContainer;